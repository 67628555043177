// Generated by Framer (7aa721f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {ymQRmGXD9: {hover: true}};

const cycleOrder = ["ymQRmGXD9"];

const variantClassNames = {ymQRmGXD9: "framer-v-1mdnszf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, TIG0cvNzJ: link ?? props.TIG0cvNzJ, ZUNbrvzj5: image ?? props.ZUNbrvzj5 ?? {src: new URL("assets/1024/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href, srcSet: `${new URL("assets/512/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href} 237w, ${new URL("assets/1024/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href} 474w, ${new URL("assets/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href} 674w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TIG0cvNzJ, ZUNbrvzj5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ymQRmGXD9", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-UeIQg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={TIG0cvNzJ} openInNewTab={false} smoothScroll><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 1454, intrinsicWidth: 674, pixelHeight: 1454, pixelWidth: 674, sizes: "min(288px, 100vw)", ...toResponsiveImage(ZUNbrvzj5)}} className={`${cx("framer-1mdnszf", className)} framer-1r4lvc8`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ymQRmGXD9"} ref={ref} style={{"--border-bottom-width": "10px", "--border-color": "rgb(1, 1, 1)", "--border-left-width": "10px", "--border-right-width": "10px", "--border-style": "solid", "--border-top-width": "10px", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.1)", ...style}} {...addPropertyOverrides({"ymQRmGXD9-hover": {"data-framer-name": undefined, background: {alt: "", fit: "fill", intrinsicHeight: 1454, intrinsicWidth: 674, pixelHeight: 1454, pixelWidth: 674, sizes: "min(288px, 100vw)", src: new URL("assets/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href, srcSet: `${new URL("assets/512/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href} 237w, ${new URL("assets/1024/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href} 474w, ${new URL("assets/HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png", import.meta.url).href} 674w`}}}, baseVariant, gestureVariant)}><motion.div className={"framer-1i4ahxj"} layoutDependency={layoutDependency} layoutId={"GJP1_8lKI"} style={{backgroundColor: "rgb(1, 1, 1)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/><motion.div className={"framer-17bq6yd"} layoutDependency={layoutDependency} layoutId={"ppD3Om4YZ"} style={{backgroundColor: "rgb(1, 1, 1)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/></Image></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-UeIQg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UeIQg .framer-1r4lvc8 { display: block; }", ".framer-UeIQg .framer-1mdnszf { height: 623px; overflow: visible; position: relative; text-decoration: none; width: 288px; }", ".framer-UeIQg .framer-1i4ahxj { flex: none; height: 20px; left: calc(50.00000000000002% - 80px / 2); overflow: hidden; position: absolute; top: 25px; width: 80px; will-change: var(--framer-will-change-override, transform); }", ".framer-UeIQg .framer-17bq6yd { bottom: 25px; flex: none; height: 7px; left: 80px; overflow: hidden; position: absolute; right: 81px; will-change: var(--framer-will-change-override, transform); }", ".framer-UeIQg .framer-v-1mdnszf .framer-1mdnszf { cursor: pointer; }", ".framer-UeIQg.framer-v-1mdnszf.hover .framer-1mdnszf { aspect-ratio: 0.4630225080385852 / 1; height: var(--framer-aspect-ratio-supported, 622px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 622
 * @framerIntrinsicWidth 288
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JVDj_ZJeV":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TIG0cvNzJ":"link","ZUNbrvzj5":"image"}
 */
const Framerb8MiBOjhp: React.ComponentType<Props> = withCSS(Component, css, "framer-UeIQg") as typeof Component;
export default Framerb8MiBOjhp;

Framerb8MiBOjhp.displayName = "Phone";

Framerb8MiBOjhp.defaultProps = {height: 622, width: 288};

addPropertyControls(Framerb8MiBOjhp, {TIG0cvNzJ: {title: "Link", type: ControlType.Link}, ZUNbrvzj5: {__defaultAssetReference: "data:framer/asset-reference,HeLyP4ZOwxL9i4yGFUGkvdfOYyk.png?originalFilename=Vector+%281%29.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerb8MiBOjhp, [])